document.addEventListener('DOMContentLoaded', function() {

  const searchForm = document.getElementById('faq-search');
  const resultsDiv = document.getElementById('faq-results');
  const ajaxUrl = '/wp-admin/admin-ajax.php';

  let searchTimer; // Timer for small delay on search



  // Function to search FAQs
  function searchFaqs(query) {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', ajaxUrl, true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.onload = function() {
          if (xhr.status >= 200 && xhr.status < 400) {
              const data = JSON.parse(xhr.responseText);
              displayResults(data);
          } else {
              console.error('Error: ' + xhr.statusText);
          }
      };
      xhr.onerror = function() {
          console.error('Request failed');
      };
      
      let url = 'action=search_faqs&query=' + encodeURIComponent(query);
      
      xhr.send(url);
  }

  // Event listener for input in search form
  searchForm.addEventListener('input', function() {
     clearTimeout(searchTimer); // Clear the previous timer
      const query = searchForm.value.trim();
      if (query !== '') {
        // delay search when user types 
        searchTimer = setTimeout(function() {
            searchFaqs(query);
        }, 300)
      } else {
       resultsDiv.innerHTML = '';
      }
  });


  function displayResults(data) {
    let html = '';
    if (data.length > 0) {
        html += '<div class="accordion" id="faqAccordion">';
        data.forEach(function(faq, index) {
            html += '<div class="accordion-item">';
            html += '<h2 class="accordion-header" id="faqHeading' + index + '">';
            html += '<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse' + index + '" aria-expanded="false" aria-controls="faqCollapse' + index + '">';
            html += faq.title;
            html += '</button>';
            html += '</h2>';
            html += '<div id="faqCollapse' + index + '" class="accordion-collapse collapse" aria-labelledby="faqHeading' + index + '" data-bs-parent="#faqAccordion">';
            html += '<div class="accordion-body">';
            html += faq.content;
            html += '</div>';
            html += '</div>';
            html += '</div>';
        });
        html += '</div>';
    } else {
        html = '';
    }
    resultsDiv.innerHTML = html;
}

});
