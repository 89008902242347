import Splide from "@splidejs/splide";
import { Grid } from "@splidejs/splide-extension-grid";
/*
 * default init
 */
// new Splide(".splide").mount();
// new Splide("#splide").mount({ Grid });
/*
 * for loop
 */
var elms = document.getElementsByClassName("splide");

for (var i = 0; i < elms.length; i++) {
  new Splide(elms[i], {
    perPage: 1,
    focus: 1,
    gap: "3.5rem",
    omitEnd: true,
    pagination: false,
    mediaQuery: "min",
    breakpoints: {
      768: {
        perPage: 2,
      },
      1200: {
        perPage: 3,
      },
      1440: {
        perPage: 4,
      },
    },
  }).mount();
}

document.addEventListener("DOMContentLoaded", function () {
  var main = new Splide("#main_hero_slider", {
    type: "fade",
    perPage: 1,
    perMove: 1,
    gap: 10,
    focus: "center",
    rewind: true,
    pagination: true,
    arrows: false,
    breakpoints: {
      768: {
        focus: 0,
        gap: 0,
        trimSpace: true,
      },
    },
  });
  var thumbnails = new Splide("#main_hero_thumbnail", {
    fixedWidth: 150,
    fixedHeight: 150,
    gap: 10,
    // focus: "center",
    rewind: true,
    pagination: false,
    arrows: false,
    isNavigation: true,
    // breakpoints: {
    //   600: {
    //     fixedWidth: 60,
    //     fixedHeight: 44,
    //   },
    // },
  });

  main.sync(thumbnails);
  main.mount();
  thumbnails.mount();
});
