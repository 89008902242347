document.addEventListener('DOMContentLoaded', function() {
  const categories = document.querySelectorAll('.product-category');
  const ajaxUrl = '/wp-admin/admin-ajax.php';

  const productListings = document.getElementById('product-listings');

  const productFaqs = document.getElementById('product-faqs');
  const productGuides = document.getElementById('product-guides');
  const productInformation = document.getElementById('product-information');
  
  const results = document.getElementById('results');



  categories.forEach(function(category) {
    
      category.addEventListener('click', function() {

          const categoryId = this.getAttribute('data-term-id');

           // Remove 'active' class from all products
           categories.forEach(function(cat) {
            cat.classList.remove('active');
          });

          // Add 'active' class to the clicked product
          category.classList.add('active');

          const xhr = new XMLHttpRequest();
          xhr.open('POST', ajaxUrl, true);
          xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
          xhr.onreadystatechange = function() {
              if (xhr.readyState === 4 && xhr.status === 200) {

                  //display products
                  productListings.innerHTML = JSON.parse(xhr.responseText);

                  // Check if a divider already exists
                  if (!document.querySelector('.products-divider')) {
                    // Add a divider after the productListings
                    productListings.insertAdjacentHTML('afterend', '<div class="products-divider  divider divider--black"></div>');
                  }

                   // Clear existing content of productFaqs, productGuides, and productInformation
                    productFaqs.innerHTML = '';
                    productGuides.innerHTML = '';
                    productInformation.innerHTML = '';


                    //clear reset button and divider

                    if (document.querySelector('.product-information-divider')) {
                      document.querySelector('.product-information-divider').remove();
                    }
                   
                    if (document.getElementById('resetButton')) {
                     document.getElementById('resetButton').remove();
                    }
 

                   // Add event listeners to newly loaded products
                   addProductEventListeners();


              } else {
                console.error('Error: ' + xhr.statusText);
            }
          };
          xhr.onerror = function() {
            console.error('Request failed');
          };
          xhr.send('action=get_products_by_category&category_id=' + categoryId);
      });
  });



  function addProductEventListeners() {
    const products = document.querySelectorAll('.product');
    products.forEach(function(product) {
      product.addEventListener('click', function() {
        const productId = this.getAttribute('data-product-id');
        
          // Remove 'active' class from all products
          products.forEach(function(prod) {
            prod.classList.remove('active');
          });

          // Add 'active' class to the clicked product
          product.classList.add('active');


        // Send AJAX request to get FAQ's , Guides and Product technical information
        const xhr = new XMLHttpRequest();
        xhr.open('POST', ajaxUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4 && xhr.status === 200) {

            const responseData = JSON.parse(xhr.responseText);

            //product faqs
            productFaqs.innerHTML = responseData.faqs;

            //product guides
            productGuides.innerHTML = responseData.productGuides;

            //product information
            productInformation.innerHTML = responseData.productInformation;

           
              if (!document.getElementById('resetButton')) {

                // Add button to reset view
                const resetButton = document.createElement('button');
                resetButton.setAttribute("id", 'resetButton');
                resetButton.setAttribute("class", 'cta -outline -black');
                
                resetButton.textContent = 'Back to Start';

                resetButton.addEventListener('click', function() {
                  // Clear existing content of productFaqs, productGuides, and productInformation
                  productListings.innerHTML = '';
                  productFaqs.innerHTML = '';
                  productGuides.innerHTML = '';
                  productInformation.innerHTML = ''


                  //remove products listing divider
                  const productListingDivider = document.querySelector('.products-divider');
                  if (productListingDivider) {
                    productListingDivider.remove();
                  }

                  //remove product info divider
                
                  const productInfoDivider = document.querySelector('.product-information-divider');
                  if (productInfoDivider) {
                    productInfoDivider.remove();
                  } 

                  //remove the reset button 
                  resetButton.remove();

                  //remove the active class on categories
                  categories.forEach(function(category) {
                    if(category.classList.contains('active')) {
                      category.classList.remove('active');
                    }
                  });   

                });


                results.appendChild(resetButton);

                  if (!document.querySelector('.product-information-divider')) {
                    // Add a divider before the reset button 
                    resetButton.insertAdjacentHTML('beforebegin', '<div class="product-information-divider divider divider--black"></div>');
                  }

            }


          } else {
            console.error('Error: ' + xhr.statusText);
          }
        };
        xhr.onerror = function() {
          console.error('Request failed');
        };
        xhr.send('action=get_product_data&product_id=' + productId);
      });
    });
  }

});
